import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import Navbar from './navbar/navbar'
// import Header from './header'
import '../assets/fonts.scss'
import '../assets/main.scss'
import './layout.css'
import './global.scss'
import { StaticQuery, graphql } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import favicon from '../images/picture_north_logo_blue.svg'
import vidPoster from '../images/Home_Page_Social_Media_Thumb_02.jpg'


library.add(fab, faEnvelope, faBars, faTimes, faPhone)

const Layout = ({ children, data, extraClasses }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <div className={`main-wrap ${extraClasses}`}>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: 'Video Production, Production House, Chicago' },
          ]}
          link={[
              { rel: 'icon', type: 'image/svg', href: `${favicon}` }
          ]}
        >
        <html lang="en" />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={data.site.siteMetadata.siteUrl+vidPoster} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
        name="twitter:creator"
        content={'picture_north'}
        />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:description" content={data.site.siteMetadata.description} />
        <meta name="twitter:image" content={data.site.siteMetadata.siteUrl+vidPoster} />
        <meta name="facebook-domain-verification" content="xwz715k45l2r6i19iqoiw7btd3jw5v" />
        </Helmet>
          {children}
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
