/* eslint react/jsx-no-comment-textnodes: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import socialImage from '../images/Contact_Page_Social_Media_Thumb_01.jpg'
import '../assets/contact.scss'
import map from '../images/Map.png';

const regions = [
  {
    'region': 'West Coast',
    'salesperson': 'Veronica Lombardo',
    'email': 'veronica@vlm.tv',
    'phone': '(424) 465-0559',
    'producer': 'Ryan Patrick',
    'producerEmail': 'ryan@picturenorth.com'
  },
  {
    'region': 'Midwest',
    'salesperson': 'Sonia Blum',
    'email': 'sonia@soniareps.com',
    'phone': '(312) 519-0911',
    'producer': 'Neha Schultz',
    'producerEmail': 'neha@picturenorth.com'
  },
  {
    'region': 'East Coast',
    'salesperson': 'Brandon Pico',
    'email': 'brandon@hapa.tv',
    'phone': '(917) 530-8614',
    'producer': 'Martin Rodahl',
    'producerEmail': 'martin@picturenorth.com'
  },
];

const contactColumn = [
  {
    'title': 'HEAD OF PRODUCTION',
    'name': 'Matt Bobbit',
    'email': 'matt@picturenorth.com',
  },
  {
    'title': 'HEAD OF OPERATIONS',
    'name': 'Michael Nowicki',
    'email': 'michael@picturenorth.com',
  },
  {
    'title': 'PRESS & PUBLICITY',
    'name': 'Evan Staley',
    'email': 'evan@picturenorth.com',
  },
  {
    'title': 'Billing & Accounts',
    'email': 'billing@picturenorth.com',
    'phone': '(312) 724-7136',
  },
  {
    'title': 'General Contact',
    'email': 'hello@picturenorth.com',
    'phone': '(312) 724-7136',
  }


]

const contact = (props) =>(
  <Layout>
    <Helmet >
      <title>Picture North | Contact Us</title>
      <meta name="description" content="Whether you have a project you want to discuss, need a customized reel from one of our directors, or want to inquire about our internship program, this is the best way to get in touch with us!" />
      <meta property="og:title" content='Picture North | Contact Us' />
      <meta property="og:description" content='Whether you have a project you want to discuss, need a customized reel from one of our directors, or want to inquire about our internship program, this is the best way to get in touch with us!' />
      <meta property="og:image" content={props.data.site.siteMetadata.siteUrl+socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
      name="twitter:creator"
      content={'picture_north'}
      />
      <meta name="twitter:title" content='Picture North | Contact Us' />
      <meta name="twitter:description" content='Whether you have a project you want to discuss, need a customized reel from one of our directors, or want to inquire about our internship program, this is the best way to get in touch with us!' />
      <meta name="twitter:image" content={props.data.site.siteMetadata.siteUrl+socialImage} />

    </Helmet>
    <div className='contact-page '>
      <div className='row no-gutters contact-header text-center'>
        <div className='container'>
          <div className='col-12 content'>
            <h1>Contact</h1>
          </div>
        </div>
      </div>
      <div className="container contact-content">
        <div className="row">
          <div className="col-md-8 d-flex flex-column">
            <div className="row">
              {regions.map( region => (
                <div className="col-md-4 contact-info">
                  <h2>{region.region}</h2>
                  <h4>Sales</h4>
                  <ul className='address'>
                    <li>{region.salesperson}</li>
                    <li><a href={`mailto:${region.email}`} >{region.email}</a></li>
                    <li><a href={`tel:${region.phone}`} >{region.phone}</a></li>
                  </ul>
                  <h4>Executive Producer</h4>
                  <ul className='address'>
                    <li>{region.producer}</li>
                    <li><a href={`mailto:${region.producerEmail}`} >{region.producerEmail}</a></li>
                  </ul>
                </div>
              ))}
            </div>
            <div className="row location">
              <div className="col-md-4">
                <h4>Office</h4>
                <img src={map} />
              </div>
              <div className="col-8 address">
                <ul>
                  <li>1132 South Wabash Avenue</li> 
                  <li>Suite 602</li>
                  <li>Chicago, IL 60605</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 right-col">
            {contactColumn.map( contact => (
              <div className="contact-block">
                <h4>{contact.title}</h4>
                <ul className='address'>
                  {contact.name && <li>{contact.name}</li>}
                  {contact.email && <li><a href={`mailto:${contact.email}`}>{contact.email}</a></li>}
                  {contact.phone && <li><a href={`mailto:${contact.phone}`}>{contact.phone}</a></li>}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default contact

export const locationPhotos = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    chicagoImage: file(absolutePath: {regex: "/Chi1.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 2000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nycImage: file(absolutePath: {regex: "/NY2.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 2000 ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`